import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import VueSession from 'vue-session'; // 추가
import VueCookies from 'vue-cookies';
import axios from 'axios';
import LoadingPlugin from './plugins/loading';
import http from './http';

import ElementUI from 'element-ui';
import locale from 'element-ui/lib/locale/lang/ko';
import 'element-ui/lib/theme-chalk/index.css'
import VueApexCharts from 'vue-apexcharts'
import ('./assets/css/default.css')
import ('./assets/css/page.css')
Vue.config.productionTip = false;
Vue.prototype.$http = http;
// Axios를 Vue 인스턴스에 글로벌로 설정
Vue.prototype.$axios = axios.create({
  baseURL: 'https://gnares.unids.kr',
});
Vue.use(ElementUI, { locale });
Vue.use(VueApexCharts);
Vue.use(VueSession); // VueSession을 전역으로 등록합니다.
Vue.use(VueCookies);
Vue.use(LoadingPlugin);


new Vue({
  router,
  store,
  session: {
    // options
  },
  render: (h) => h(App),
}).$mount('#app');