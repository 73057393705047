import axios from 'axios';

// Axios 인스턴스 생성
const http = axios.create({
  baseURL: 'https://gnares.unids.kr',
});

let cancel;

// 요청 인터셉터 추가
http.interceptors.request.use(config => {
  if (cancel) {
    cancel('Previous request canceled.');
  }
  config.cancelToken = new axios.CancelToken(c => cancel = c);
  return config;
}, error => Promise.reject(error));

// 응답 인터셉터는 필요에 따라 추가

export default http;