import Vue from 'vue';

const LoadingPlugin = {
  install(Vue) {
    Vue.prototype.$loading = function(options = {}) {
      // 기본 옵션
      const defaultOptions = {
        lock: true,
        text: "데이터 로딩중 입니다.",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      };

      // 사용자 정의 옵션과 기본 옵션을 병합
      const finalOptions = {...defaultOptions, ...options};

      // 여기서 로딩 컴포넌트를 활성화하는 로직을 구현합니다.
      // 예: Element UI의 경우
      this.$loading(finalOptions);
    };

    Vue.prototype.$loading.close = function() {
      // 로딩 컴포넌트를 비활성화하는 로직을 구현합니다.
      // 예: Element UI의 경우
      this.$loading().close();
    };
  },
};

export default LoadingPlugin;