import Vue from "vue";
import VueRouter from "vue-router";
import VueSession from "vue-session";
Vue.use(VueRouter);
Vue.use(VueSession);
const routes = [
  {
    path: "/",
    name: "LoginLayout",
    component: () => import("../layouts/loginLayout.vue"),
    children: [
      {
        path: "/",
        name: "login",
        component: () => import("../views/login.vue"),
      },
    ],
  },
  {
    path: "/index",
    name: "DefaultLayout",
    component: () => import("../layouts/defaultLayout.vue"),
    meta: {
      requiresAuth: true, // 이 경로에 접근하려면 인증이 필요합니다
    },
    children: [
      {
        path: "/index",
        name: "index",
        component: () => import("../views/index.vue"),
      },
      {
        path: "/index2",
        name: "index2",
        component: () => import("../views/index2.vue"),
      },
      {
        path: "/notice_list",
        name: "noticeList",
        component: () => import("../views/notice_list.vue"),
      },
      {
        path: "/notice_write",
        name: "noticeWrite",
        component: () => import("../views/notice_write.vue"),
      },
      {
        path: "/notice_view",
        name: "noticeView",
        component: () => import("../views/notice_view.vue"),
      },
      {
        path: "/environmental",
        name: "environmental",
        component: () => import("../views/environmental.vue"),
      },
      {
        path: "/environmental2",
        name: "environmental2",
        component: () => import("../views/environmental2.vue"),
      },
      {
        path: "/environmentalManage",
        name: "environmentalManage",
        component: () => import("../views/environmentalManage.vue"),
      },
      {
        path: "/environmentalManagement",
        name: "environmentalManagement",
        component: () => import("../views/environmentalManagement.vue"),
      },
      {
        path: "/growthinfo",
        name: "growthInfo",
        component: () => import("../views/growthinfo.vue"),
      },
      {
        path: "/growthinfo2",
        name: "growthInfo2",
        component: () => import("../views/growthinfo2.vue"),
      },
      {
        path: "/growthinfo3",
        name: "growthInfo3",
        component: () => import("../views/growthinfo3.vue"),
      },
      {
        path: "/growthManagement",
        name: "growthManagement",
        component: () => import("../views/growthManagement.vue"),
      },
      {
        path: "/growthinfoAdd",
        name: "growthInfoAdd",
        component: () => import("../views/growthinfoAdd.vue"),
      },
      {
        path: "/growthinfoManage",
        name: "growthinfoManage",
        component: () => import("../views/growthinfoManage.vue"),
      },
      {
        path: "/collectedData",
        name: "collectedData",
        component: () => import("../views/collectedData.vue"),
      },
      {
        path: "/farmManage",
        name: "farmManage",
        component: () => import("../views/farmManage.vue"),
      },
      {
        path: "/userManage",
        name: "userManage",
        component: () => import("../views/userManage.vue"),
      },
      {
        path: "/userAdd",
        name: "userAdd",
        component: () => import("../views/userAdd.vue"),
      },
      {
        path: "/userUpdate",
        name: "userUpdate",
        component: () => import("../views/userUpdate.vue"),
      },
      {
        path: "/farmUpdate",
        name: "farmUpdate",
        component: () => import("../views/farmUpdate.vue"),
      },
      {
        path: "/optionManage",
        name: "optionManage",
        component: () => import("../views/optionManage.vue"),
      },
      {
        path: "/cropsManage",
        name: "cropsManage",
        component: () => import("../views/cropsManage.vue"),
      },
      {
        path: "/badgeManage",
        name: "badgeManage",
        component: () => import("../views/badgeManage.vue"),
      },
      {
        path: "/companyManage",
        name: "companyManage",
        component: () => import("../views/companyManage.vue"),
      },
      {
        path: "/salesManage",
        name: "salesManage",
        component: () => import("../views/salesManage.vue"),
      },
      {
        path: "/salesAdd",
        name: "salesAdd",
        component: () => import("../views/salesAdd.vue"),
      },
      {
        path: "/reportManage",
        name: "reportManage",
        component: () => import("../views/reportManage.vue"),
      },
      {
        path: "/reportView",
        name: "reportView",
        component: () => import("../views/reportView.vue"),
      },
      {
        path: "/dataComparison",
        name: "dataComparison",
        component: () => import("../views/dataComparison.vue"),
      },
      {
        path: "/dataComparison2",
        name: "dataComparison2",
        component: () => import("../views/dataComparison2.vue"),
      },
      {
        path: "/dataComparison3",
        name: "dataComparison3",
        component: () => import("../views/dataComparison3.vue"),
      },
      {
        path: "/management",
        name: "management",
        component: () => import("../views/management.vue"),
      },
      {
        path: "/framDataComparison",
        name: "framDataComparison",
        component: () => import("../views/framDataComparison.vue"),
      }, 
      {
        path: "/framDataComparison2",
        name: "framDataComparison2",
        component: () => import("../views/framDataComparison2.vue"),
      }, 
      {
        path: "/framDataComparison3",
        name: "framDataComparison3",
        component: () => import("../views/framDataComparison3.vue"),
      }, 
      {
        path: "/productionForecast",
        name: "productionForecast",
        component: () => import("../views/productionForecast.vue"),
      },
                  {
        path: "/sampleAnalysis",
        name: "sampleAnalysis",
        component: () => import("../views/sampleAnalysis.vue"),
      },
      {
        path: "/sampleAnalysis2",
        name: "sampleAnalysis2",
        component: () => import("../views/sampleAnalysis2.vue"),
      },
      {
        path: "/sampleAnalysis3",
        name: "sampleAnalysis3",
        component: () => import("../views/sampleAnalysis3.vue"),
      },
      {
        path: "/cctv",
        name: "cctv",
        component: () => import("../views/cctv.vue"),
      },
      {
        path: "/report",
        name: "report",
        component: () => import("../views/report.vue"),
      },
      {
        path: "/inspection_cctv",
        name: "inspection_cctv",
        component: () => import("../views/inspection_cctv.vue"),
      },
      {
        path: "/inspection_sensor",
        name: "inspection_sensor",
        component: () => import("../views/inspection_sensor.vue"),
      },
    ],
  },
  {
    path: "/ClientIndex",
    name: "ClientLayout",
    component: () => import("../layouts/clientLayout.vue"),
    meta: {
      requiresAuthUser: true, // 이 경로에 접근하려면 인증이 필요합니다
    },
    children: [
      {
        path: "/clientIndex",
        name: "clientIndex",
        component: () => import("../views/client/index.vue"),
      },
            {
        path: "/clientFramDataComparison",
        name: "clientFramDataComparison",
        component: () => import("../views/client/clientFramDataComparison.vue"),
      },
      {
        path: "/UserManagement",
        name: "UserManagement",
        component: () => import("../views/client/UserManagement.vue"),
      },
      {
        path: "/UserReportInquiry",
        name: "UserReportInquiry",
        component: () => import("../views/client/UserReportInquiry.vue"),
      },
    ],
  },
  {
    path: "/error",
    name: "error",
    component: () => import("../views/error.vue"), // 에러 페이지 컴포넌트
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    const accessToken = Vue.$cookies.get("accessToken");
    const expireDate = Vue.$cookies.get("expireDate");
    const userType = Vue.$cookies.get("user_type"); // 쿠키에서 user_type을 가져옵니다.

    if (!accessToken || !expireDate) {
      Vue.prototype.$message({
        message: "로그인 후 이용해주세요.",
        type: "warning",
      });
      next({ name: "login" });
    } else if (userType !== "master") { // user_type이 'master'가 아닌 경우에 메시지를 표시합니다.
      Vue.prototype.$message({
        message: "접근 권한이 없습니다.",
        type: "warning",
      });
      next(false); // 접근을 차단하고 해당 라우터로 이동하지 않습니다.
    } else {
      const currentTime = new Date().getTime();
      const expirationTime = new Date(expireDate).getTime();

      if (currentTime > expirationTime) {
        Vue.$cookies.remove("accessToken");
        Vue.$cookies.remove("expireDate");

        Vue.prototype.$message({
          message: "로그인 세션이 만료되었습니다.",
          type: "warning",
        });

        next({ name: "login" });
      } else {
        next();
      }
    }
  } else {
    next();
  }
  if (to.matched.length === 0) {
    // 정의되지 않은 라우트로의 접근 시 에러 페이지로 리다이렉트
    next({ name: "error" }); // "error" 는 에러 페이지의 라우트 이름이어야 합니다.
  }
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuthUser)) {
    const accessToken = Vue.$cookies.get("accessToken");
    const expireDate = Vue.$cookies.get("expireDate");
    const userType = Vue.$cookies.get("user_type"); // 쿠키에서 user_type을 가져옵니다.

    if (!accessToken || !expireDate) {
      Vue.prototype.$message({
        message: "로그인 후 이용해주세요.",
        type: "warning",
      });
      next({ name: "login" });
    } else {
      const currentTime = new Date().getTime();
      const expirationTime = new Date(expireDate).getTime();

      if (currentTime > expirationTime) {
        Vue.$cookies.remove("accessToken");
        Vue.$cookies.remove("expireDate");

        Vue.prototype.$message({
          message: "로그인 세션이 만료되었습니다.",
          type: "warning",
        });

        next({ name: "login" });
      } else {
        next();
      }
    }
  } else {
    next();
  }
  if (to.matched.length === 0) {
    // 정의되지 않은 라우트로의 접근 시 에러 페이지로 리다이렉트
    next({ name: "error" }); // "error" 는 에러 페이지의 라우트 이름이어야 합니다.
  }
});

export default router;
