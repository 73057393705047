import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    farmList: []
  },
  mutations: {
    setFarmList(state, farmList) {
      state.farmList = farmList;
    }
  },
  actions: {
    fetchFarmList({ commit }) {
      const apiUrl = "/farmers/farm_list";
      const config = {
        headers: {
          Authorization: `Bearer ${Vue.$cookies.get("accessToken")}`,
          Accept: "application/json",
        },
      };
      axios.get('https://gnares.unids.kr' + apiUrl, config)
        .then((response) => {
          const farmList = response.data.data.map((item) => ({
            label: item.farm_name,
            value: item.farm_id,
          }));
          commit('setFarmList', farmList);
        })
        .catch((error) => {
          console.error("API 요청 중 오류 발생:", error);
        });
    }
  }
});